@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500&display=swap");

.footer {
    padding: 156px 0px 40px 0px;
    background: #0d0e1f 0% 0% no-repeat padding-box;
    position: relative;
    overflow-y: auto;
    overscroll-behavior-y: contain;
    scroll-snap-type: y mandatory;
}

.footer>div:last-child {
    scroll-snap-align: start;
}

@media screen and (max-width: 540px) {
    .footer {
        padding: 0px 0px 40px 0px;
        background: #0d0e1f 0% 0% no-repeat padding-box;
        position: relative;
    }
}

.footerPurple {
    position: absolute;
    width: 71px;
    height: 66px;
    top: 300px;
    left: 40px;
}

.footerYellow {
    position: absolute;
    width: 71px;
    height: 66px;
    top: 620px;
    right: 5px;
}

.swiggle1 {
    position: absolute;
    top: 140px;
    width: 95px;
    left: 209px;
    height: 9px;
}

.footerGreenSwiggle {
    position: absolute;
    right: 10px;
    top: 450px;
    width: 94px;
    height: 14px;
}

.blueLine {
    position: absolute;
    width: 1100px;
    top: 480px;
    left: 60px;
}

.greenLine {
    position: absolute;
    width: 1100px;
    top: 200px;
    right: 50px;
}

.footer h1 {
    margin: 0;
}

.footerContent {
    width: 90%;
    top: 40px;
    max-width: 1300px;
    margin: auto;
    min-height: 494px;
    background: #1de592;
    padding: 40px 60px 20px;
    border-radius: 40px;
    overflow-y: hidden;
    position: relative;
}

.footerImage {
    position: absolute;
    height: 594px;
    transform: translateY(50px);

}

.footerPhoneImage {
    position: absolute;
    height: 54px;
    transform: translateY(118px);
    margin-left: 60px;
    z-index: 999;
}

.footerInfo {
    margin-top: 80px;
}

.footerInfo h2 {
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    margin-bottom: 20px;
}

.footerInfo p {
    font-size: 18px;
    line-height: 1.6;
}

.platforms {
    display: flex;
    margin-top: 53px;
}

.platform {
    background-color: #000;
    color: #fff;
    display: flex;
    align-items: center;
    padding: 4px 20px;
    border-radius: 10px;
    margin-right: 8px;
    cursor: pointer;
}

.platFormContent {
    display: flex;
    flex-direction: column;
    margin-left: 8px;
}

.platFormContent span {
    font-weight: 300;
    font-size: 14px;
}

.platFormContent .name {
    font-weight: 500;
    font-size: 20px;
}

.platformLogo {
    display: flex;
    align-items: center;
    height: 30px;
    width: 30px;
}

.platformLogo img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.footerLinks {
    width: 100%;
    border-top: 1px solid #7e8096;
    padding-top: 40px;
    max-width: 1300px;
    margin: auto;
    margin-top: 110px;
}

.footerLink {
    color: #7e8096;
    line-height: 2em;
    margin-top: 65px;
    font-weight: 300;
    list-style: none;
}

.small {
    margin-top: 10px;
}

.extra {
    line-height: 1.6em;
    margin-top: 53px;
    font-size: 20px;
}

.logoContainer {
    color: #fff;
}

.logoContainer img {
    width: 150.03px;
    height: 58.94px;
}

.footerHeader {
    color: #fff;
    font-size: 24px;
    display: flex;
}

.footerSocials {
    width: 100%;
    max-width: 1300px;
    margin: auto;
    margin-top: 76px;
}

.footerSocials i {
    color: #b1b1b1;
    margin-right: 40px;
    font-size: 24px;
}

.footerSocials p {
    font: normal normal normal 16px/24px Montserrat;
    letter-spacing: 0.8px;
    color: #7e8096;
}

@media screen and (max-width: 600px) {
    .platforms {
        flex-direction: column;
    }

    .platform {
        margin-bottom: 12px;
        justify-content: center;
    }
}

@media screen and (max-width: 600px) {
    .footerSocials {
        display: flex;
        flex-direction: column !important;
        align-items: center;
    }

    .footerImage {
        display: block;
    }

    .footerSocials p {
        margin-top: 20px;
    }
}