.working {
  min-height: 100vh;
  display: flex;
  background-color: white;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.design1 {
  position: absolute;
  top: 84px;
  left: 1300px;
  height: 208px;
  width: 1537px;
}

.design1 img {
  width: 100%;
}

.swiggle1 {
  position: absolute;
  right: -35px;
  height: 12px;
  bottom: 120px;
  width: 125px;
}

.swiggle2 {
  position: absolute;
  right: 60px;
  bottom: 90px;
  width: 125px;
  height: 12px;
}

.design2 {
  position: absolute;
  bottom: 0;
  height: 250px;
  left: -488.236328125px;
  max-width: 1536px;
}

.design2 img {
  /* width: 100%; */
  width: 95%;
  margin-top: 3rem;
}
