.showcase {
  background-color: #009a4f;
  min-height: 100vh;
}

.showcaseContainer {
  /* min-height: 91vh; */
  display: flex;
  flex-direction: column;
  justify-content: center;
}
