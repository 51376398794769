.showcaseImageContent {
  flex: 2;
}

.showcaseImageContainer {
  position: absolute;
  height: 87%;
  right: -120px;
  bottom: 0;
  z-index: 0;
  top: 120px;
}

.showcaseImageContainer .img1 {
  height: 100%;
  width: 83%;
  margin-left: 4rem;
  /* width: 90%; */
}

.halfCircleImage {
  position: absolute;
  bottom: 20%;
  left: 6%;
  /* bottom: 10%;
  left: -2%; */
}

.phone {
  position: absolute;
  bottom: 0%;
  top: 120px;
  left: 30px;
  right: 0%;
  /* transform: rotate(-3deg); */
}
/* .phone .phoneImg {
  height: 100%;
  width: 75%;
  transform: rotate(-20deg);
  margin: 25px 0px 0px 120px;
} */
.phone .phoneImg {
  height: 100%;
  width: 82%;
  /* transform: rotate(-3deg); */
  margin: -110px 0px 0px 50px;
}

.phone img {
  width: 100%;
}

/* .phone img {
  height: 100%;
  width: 75%;
  transform: rotate(-24deg);
  margin: 90px 0px 0px 120px;
} */

.phone .logoText {
  position: relative;
  /* top: 22%;
  left: 228px; */
  width: 20%;
  top: 20%;
  left: 210px;
  z-index: 999;
}

.showcaseMessageLogo {
  /* position: relative; */
  top: 8%;
  /* right: 30%; */
  right: 43%;
}

.showcaseMessageLogo img {
  height: 80px;
}

@media screen and (min-width: 1000px) and (max-width: 1250px) {
  .phone .logoText {
    top: 26%;
    left: 246px;
  }

  .phone .phoneImg {
    margin: -92px 0px 0px 50px;
  }
}
