.showcaseContent {
  z-index: 2;
  position: relative;
  left: -90px;
}

.showcaseContentTitle {
  color: #fff;
  position: relative;
  font-weight: bold;
  font-size: 65px;
}

.showcaseContentTitle .text {
  position: relative;
  display: inline-block;
  padding-top: 0.6em;
  padding-right: 0.05em;
  overflow: hidden;
}

.showcaseContent p {
  color: #fff;
  font-size: 20px;
  line-height: 1.5;
  font-weight: medium;
}

.btn {
  border: none;
  background: #ffb73d 0% 0% no-repeat padding-box;
  border-radius: 28px;
  opacity: 1;
  padding: 16px 18px;
  display: flex;
  align-items: center;
  margin-top: 40px;
}

.btn span {
  margin: 0px 4px;
  padding: 0px 12px;
  font-size: 18px;
}

.btnIcon i {
  padding-right: 6px;
  transform: scale(3.2, 1);
}

.platforms {
  display: flex;
  margin-top: 53px;
}

.platform {
  background-color: #000;
  color: #fff;
  display: flex;
  align-items: center;
  padding: 4px 20px;
  border-radius: 10px;
  margin-right: 8px;
  cursor: pointer;
}

.platFormContent {
  display: flex;
  flex-direction: column;
  margin-left: 8px;
}

.platFormContent span {
  font-weight: 300;
  font-size: 14px;
}

.platFormContent .name {
  font-weight: 500;
  font-size: 20px;
}

.platformLogo img {
  height: 30px;
  /* width: 100%; */
  object-fit: cover;
}

@media screen and (max-width: 540px) {
  .showcaseContentTitle {
    font-size: 30px;
  }
  .platforms {
    flex-direction: column;
  }
  .platform {
    margin-bottom: 12px;
    justify-content: center;
  }
}

@media screen and (max-width: 992px) {
  .showcaseContent {
    margin-top: 80px;
    margin-left: 90px;
  }

  .showcaseContentTitle span {
    line-height: 1.4;
  }
}

@media screen and (max-height: 528px) {
  .showcaseContent {
    margin-top: 86px;
  }
}
