@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500&display=swap");

.policy {
  padding-top: 150px;
  position: relative;
}

.policy1 {
  padding-top: 150px;
  position: relative;
}

.header h1 {
  font: normal normal bold 52px/80px Inter;
  letter-spacing: 0px;
  color: #000000;
  margin: 0;
  padding: 0;
}

.header1 h1 {
  font: normal normal bold 52px/80px Inter;
  letter-spacing: 0px;
  color: #000000;
  padding-top: 45px !important;
  margin: 0;
  padding: 0;
}

.span1 {
  display: inline-block;
  width: 26px;
  height: 8px;
  background: #009a4f 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  margin-right: 8px;
}

.span2 {
  display: inline-block;
  width: 78px;
  height: 8px;
  background: #009a4f 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
}

.time {
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  margin-top: 42px;
  text-align: left;
  letter-spacing: 0px;
  color: #000000;
}

.update {
  font-size: 18px;
  letter-spacing: 0px;
  color: #5d5d5d;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0px;
  opacity: 1;
}

.content {
  font-weight: 300;
  margin-top: 40px;
  line-height: 1.8em;
}

.content2 {
  margin-top: 40px;
}

.content2 h2 {
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 16px;
}

.content2 p {
  margin-bottom: 20px;
}

.primary {
  color: #009a4f;
  cursor: pointer;
}

.list {
  list-style: none;
  margin-bottom: 100px;
  text-decoration: none;
}

.listItem {
  margin: 10px 0px;
  display: flex;
  align-items: center;
}

.listItem2 {
  margin: 10px 0px;
  display: flex;
  flex-direction: column;
}

.line {
  margin-right: 10px;
  border-radius: 10px;
  height: 4px;
  width: 24px;
  background-color: #009a4f;
  display: inline-block;
}

.linePosition {
  display: flex;
  align-self: flex-start;
  margin-top: 0.8rem;
}

.design1 {
  position: absolute;
  left: -988.836328125px;
  top: 74px;
  /* bottom: 0; */
  height: 250px;
}

.design1 img {
  width: 48%;
  margin-top: 3rem;
}

.design2 {
  position: absolute;
  bottom: 0px;
  right: -1000px;
  height: 250px;
}

.design2 img {
  /* width: 100%; */
  transform: rotate(180deg) translateX(-1350px);
  width: 48%;
  margin-top: 3rem;
}

.design3 img {
  /* width: 100%; */
  transform: rotate(0deg) translateX(-1350px);
  width: 48%;
  margin-top: 3rem;
}

.redirectid {
  color: blue;
  cursor: pointer;
}

.textbold {
  font-weight: 600;
}

.bullet_point {
  margin-right: 10px;
  border-radius: 10px;
  margin-top: -30px !important;
  height: 4px;
  width: 24px;
  background-color: #009a4f;
  display: inline-block;
}