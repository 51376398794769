@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap");

.workingContent {
  height: 509.11px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.workingContent h2 {
  font-size: 40px;
  font-weight: bold;
  font: normal normal bold 40px/58px Inter;
}

.info {
  font: normal normal medium 22px/30px Montserrat;
  margin-top: 20px;
  font-size: 22px;
  color: #919191;
}

.desc {
  font: normal normal medium 16px/32px Inter;
  margin-top: 31px;
  font-size: 16px;
  line-height: 1.6;
}
