.workingImageContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.circle {
  background: #ffb73d;
  height: 509.11px;
  width: 509.11px;
  border-radius: 50%;
  position: relative;
}

.phone {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.message {
  position: absolute;
  height: 79px;
  width: 79px;
  top: 0;
  right: 0;
  transform: translate(20px, -20px);
}

.semiCircle {
  position: absolute;
  width: 90px;
  height: 51px;
  left: 0;
  bottom: 122px;
  transform: translateX(-30px);
}