.navbar {
    background-color: #009a4f !important;
    /* position: static !important; */
    position: sticky;
    /* z-index: 999 !important; */
    z-index: 1;
}

.navLink {
    margin: 0px 2rem;
    margin-left: 5%;
}

.logo {
    margin-left: -90px;
}

.navLink a {
    font: normal normal medium 19px/26px Inter;
    letter-spacing: 0px;
    color: #fff !important;
    text-decoration: none;
    padding-bottom: 0.4rem;
}

@media screen and (max-width: 960px) {
    .navLink {
        margin: 0.81rem 0;
        text-align: center;
        padding: 0.2rem 0rem;
        transition: all 0.2s ease;
    }

    .navLink:hover {
        background-color: #038848 !important;
    }

    .logo {
        margin-left: 5px;
    }

    .navLink a {
        border-bottom: none !important;
    }
}